class HostUrl {
  constructor() {
    // 从localStorage中获取当前IdaasApp
    this.apiURL = localStorage.getItem('apiURL')
  }

  getUrl(name) {
    if (process.env.NODE_ENV === 'development') {
      return '/' + name.toLocaleLowerCase() + '_api'
    }
    // 返回当前IdaasApp的baseUrl
    return this.apiURL + `/${name.toLocaleLowerCase()}api`
  }
}

export default HostUrl
