import HostUrl from '@/utils/HostUrl.js'

let hostUrl = new HostUrl()

let goods_host = hostUrl.getUrl('GOODS'),
  host = hostUrl.getUrl('OMS'),
  task_host = hostUrl.getUrl('REPORT'),
  idaas_host = hostUrl.getUrl('IDAAS'),
  shop_host = hostUrl.getUrl('SHOP')

export default {
  //oms接口
  uploadBatchOrderFile: host + '/file/upload', //通过Excel上传录单，然后上传
  searchProductGroup: host + '/product_group/key/list', //组合商品搜索
  searchSkuByNameOrBarcodeOrSkucode: host + '/sku/keys', //skuCode Name Barcode => skuName
  businessUnitList: host + '/businessUnit/list', //业务组信息

  exportOMSOrder: task_host + '//task/create', //oms导出

  //product接口
  queryCategoryList: goods_host + '/category/list', //获取类目列表
  createCategory: goods_host + '/category/create', //创建类目
  queryPlatformCategoryTree: goods_host + '/category/queryPlatformCategory', //获取全平台类目树
  commitLazadaGoods: goods_host + '/lazadaGoods/commit', //提交审核平台商品
  queryCategoryTreeByOmsCategory: goods_host + '/category/queryCategoryTreeByOmsCategory', //获取Lazada类目树
  queryPublishAttribute: goods_host + '/platform/queryAttribute', //查询必填属性
  batchCreateWmsGroupGoods: goods_host + '/sku/batchCreateWmsGroupGoods', //批量发布组合商品到wms
  batchCreateWmsGoods: goods_host + '/sku/batchCreateWmsGoods', //批量选择商品创建WMS商品
  uploadLocal: goods_host + '/platform/uploadLocal', //上传本地图片
  uploadPartyFile: goods_host + '/partyFile/upload', //上传媒体中心文件
  queryGroupSkuList: goods_host + '/groupGoods/skuList', //创建组合商品可用子sku列表
  queryLazadaBrand: goods_host + '/brand/queryPlatformBrandList', //获取平台品牌
  brandOmsList: goods_host + '/brand/mappingPageList', //获取品牌列表
  upDateBrand: goods_host + '/brand/updateWithPlatform', //修改品牌
  createBrand: goods_host + '/brand/createWithPlatform', //新增品牌
  queryCategoryMappingPageList: goods_host + '/category/mappingPageList', //类目mapping列表
  updateWithPlatformCategory: goods_host + '/category/updateWithPlatform', //修改类目mapping
  createWithPlatformCategory: goods_host + '/category/createWithPlatform', //创建类目mapping
  queryPlatformDetail: goods_host + '/platform/detail', //获取平台商品详情2
  modifyLazadaGoodsPrice: goods_host + '/lazadaGoods/modifyPrice', //修改平台商品特殊价格
  updatePlatformGoods: goods_host + '/platform/update', //更新平台商品
  auditLazadaGoods: goods_host + '/lazadaGoods/audit', //审核平台商品
  queryLazadaGoodsImage: goods_host + '/lazadaImage/pageList', //查询图片列表
  setLazadaGoodsImages: goods_host + '/lazadaImage/setImages', //设置sku图片
  inActivity: goods_host + '/lazadaImage/inActivity', //参加活动
  outActivity: goods_host + '/lazadaImage/outActivity', //退出活动
  queryActiveDate: goods_host + '/lazadaImage/queryActivityDate', //获取活动时间
  setActiveDate: goods_host + '/lazadaImage/setActivityDate', //设置活动时间
  openSyncStock: goods_host + '/platform/open/sync/stock', //同步库存开
  closeSyncStock: goods_host + '/platform/close/sync/stock', //同步库存关
  platformGoodsSync: goods_host + '/platform/sync', //下载平台商品
  queryPlatformSkuPageList: goods_host + '/platform/queryPlatformSkuPageList', //获取平台商品列表
  exportLazadaSku: goods_host + '/lazadaGoods/export', //导出平台商品列表
  batchRemoveLazadaSku: goods_host + '/platform/batchRemoveLazadaSku', //批量删除sku
  onSalePlatformGoods: goods_host + '/platform/onSale', //上架平台商品
  offSalePlatformGoods: goods_host + '/platform/offSale', //下架平台商品
  batchReplicatePublish: goods_host + '/platform/batchReplicatePublish', //批量铺货
  replicatePublish: goods_host + '/platform/replicatePublish', //铺货
  goodsSyncSkuInventory: goods_host + '/open/goods/syncSkuInventory', //更新库存
  queryInventorySyncRecordList: goods_host + '/syncStock/log/pageList', //库存同步记录
  groupGoodsBatchCreate: goods_host + '/groupGoods/batchCreate', //导出oms商品
  createGroupGoods: goods_host + '/groupGoods/save', //创建组合商品
  openOmsGoods: goods_host + '/goods/open', //启用商家商品
  closeOmsGoods: goods_host + '/goods/close', //停用商家商品
  publishGoods: goods_host + '/goods/publish', //发布商品
  batchPublishGoods: goods_host + '/goods/batch/publish', //批量发布商品
  queryGroupGoodsList: goods_host + '/groupGoods/pageList', //组合商品列表
  skuOperateLogPageList: goods_host + '/goods/skuOperateLogPageList', //OMS商品日志
  videoUpload: goods_host + '/video/upload', //上传视频
  brandList: goods_host + '/brand/list', //获取品牌列表
  omsGoodsDetail: goods_host + '/goods/detail', //OMS商品详情
  queryDefaultStyle: goods_host + '/category/queryDefaultStyle', //获取默认规格
  omsGoodsUpdate: goods_host + '/goods/update', //OMS商品更新
  omsGoodsCreateQuick: goods_host + '/goods/createQuick', //OMS快速商品创建
  omsGoodsCreate: goods_host + '/goods/create', //OMS商品创建
  uploadVideoToPlatform: goods_host + '/platform/uploadVideoToPlatform', //上传视频
  syncGoodsToScm: goods_host + '/goods/syncGoodsToScm', //同步商品到SCM
  updateSkuAttributeByWmsGoods: goods_host + '/sku/updateSkuAttributeByWmsGoods', //批量更新商品尺寸信息
  queryGoodsTagList: goods_host + '/tag/list', //商品tag列表
  batchCreateOmsGoods: goods_host + '/goods/batchCreate', //导入excel批量创建商品
  getBatchCreateGoodsExcel: goods_host + '/common/getBatchCreateGoodsExcel', //获取批量创建商品excel模板
  getBatchCreateGoodsExcelRxportUrl: goods_host + '/export/getBatchCreateGoodsExcel', //获取导出模板接口
  batchUpdateOmsGoods: goods_host + '/goods/batchUpdate', //导入excel批量更新商品
  getBatchUpdateGoodsExcel: goods_host + '/common/getBatchUpdateGoodsExcel', //获取批量更新商品excel模板
  getBatchUpdateGoodsExcelRxportUrl: goods_host + '/export/getBatchUpdateGoodsExcel', //获取导出模板接口
  batchCreateMapping: goods_host + '/skuTemp/batchCreateMapping', //导入FLASH商品档案
  syncLazadaGoods: goods_host + '/goods/syncLazadaGoods', //OMS商品同步lazada商品（下载商品）
  omsGoodsList: goods_host + '/goods/pageList', //OMS商品列表
  getUnitList: goods_host + '/goodsUnit/list', //单位列表接口

  //shop接口
  getWarehouseListNew: shop_host + '/warehouse/queryWarehousePage', //仓库列表
  getWarehouseShipperList: shop_host + '/shipper/page', //货主列表
  getNewShopInfoList: shop_host + '/shop/simplePage', //新店铺选择

  //配料表相关接口
  uploadSkuNutrition: goods_host + '//sku/nutrition/word/upload', //上传配料表word
  updateSkuProperties: goods_host + '//sku/properties/update', //更新配料表
  getSkuPropertiesDetail: goods_host + '//sku/properties/detail', //配料表详情
  getDepartmentList: idaas_host + '/open/department/listDepartment', //业务单元列表
}
