function isEmptyObject(obj) {
  // 检查参数是否为对象
  if (typeof obj !== 'object' || obj === null) {
    return true
  }

  // 检查对象是否有可枚举的属性
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      return false
    }
  }

  return true
}

export { isEmptyObject }
