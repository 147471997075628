import Vue from 'vue'
import VueRouter from 'vue-router'
//---------------------------商品管理
//平台商品
const platformGoodsList = () => import('./view/goodsManage/platformGoodsList.vue')
const platformGoodsDetail = () => import('./view/goodsManage/platformGoodsDetail.vue')
//图片管理
const platformGoodsImage = () => import('./view/goodsManage/platformGoodsImage.vue')
// OMS商品
const productsNew = () => import('./view/goodsManage/productsNew.vue')
const productsDetail = () => import('./view/goodsManage/productsDetail.vue')
//商品操作日志
const productOperationLog = () => import('./view/goodsManage/productOperationLog.vue')
// 组合商品列表
const productGroups = () => import('./view/goodsManage/productGroups.vue')
// 品类样式
const categoryManage = () => import('./view/goodsManage/categoryManage.vue')
// 品牌
const brandManage = () => import('./view/goodsManage/brandManage.vue')
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'index',
    redirect: '/platformGoods',
    component: platformGoodsList,
  },
  // goodsManage
  // 商品列表页
  {
    path: '/platformGoods',
    name: 'platformGoods',
    component: platformGoodsList, // 平台商品列表
  },
  {
    path: '/platformGoodsDetail',
    name: 'platformGoodsDetail',
    component: platformGoodsDetail, // 平台商品详情
  },
  {
    path: '/products',
    name: 'products',
    component: productsNew, // 商品列表页
  },
  {
    path: '/productsDetail',
    name: 'productsDetail',
    component: productsDetail, // 商品详情页
  },
  {
    path: 'productOperationLog',
    name: 'productOperationLog',
    component: productOperationLog, // 商品操作日志
  },
  {
    path: '/platformGoodsImage',
    name: 'platformGoodsImage',
    component: platformGoodsImage, // 图片管理
  },
  {
    path: '/categoryManage',
    name: 'categoryManage',
    component: categoryManage, // 商品类目管理
  },
  {
    path: '/productGroups',
    name: 'productGroups',
    component: productGroups, // 组合商品列表页
  },
  {
    path: '/brandManage',
    name: 'brandManage',
    component: brandManage, // 品牌管理
  },
  {
    path: '404',
    name: '404',
    component: () => import('./view/common/404'),
  },
  {
    path: '/403',
    name: '403',
    component: () => import('./view/common/403'),
  },
  // 此处需特别注意至于最底部
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  base: window.__POWERED_BY_QIANKUN_PARENT__
    ? `/${window.__QIANKUN_PARENTNAME__}/goodsManage`
    : window.__POWERED_BY_QIANKUN__
    ? '/goodsManage'
    : '/',
  mode: 'history',
  routes,
})

export default router
