import Vue from 'vue'
import './public-path'
import App from './App.vue' // 根组件
import router from './router'
import store from './store'
import './utils/antd'
import './css/index.scss'
import './lib/layer/layer'
import './lib/layer/skin/layer.css'
import VueQuillEditor from 'vue-quill-editor' // require styles 引入样式
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import breadcrumb from '@component/breadcrumb/index'
import actions from './qiankun/actions'
import i18n from './utils/lang'
import { isEmptyObject } from './common'
// 注册全局过滤器
import * as filters from './common/filters' // global filters
Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key])
})
Vue.component('breadcrumb', breadcrumb)

Vue.prototype.priv = function (name) {
  try {
    return Common.priv.hasUp(name)
  } catch (err) {
    console.log(err)
  }
}
Vue.prototype.$isEmptyObject = isEmptyObject

const env = 'dev'

Vue.config.devtools = env === 'dev' ? true : false
Vue.config.silent = env === 'dev' ? false : true

Vue.prototype.$bus = new Vue()
Vue.use(VueQuillEditor)

Vue.prototype.layer = {
  empty: () => {
    //搜索为空
    layer.msg(`<span style='color:red'>${i18n.t('layer.Empty')}~</span>`, {
      icon: 5,
    })
  },
  success: () => {
    //操作成功
    layer.msg(`${i18n.t('layer.Success')}~`)
  },
  selectAnOrder: () => {
    //选择一个订单
    layer.msg(`${i18n.t('layer.SelectAnOrder')}~`)
  },
}

let instance = null
function render(props = {}) {
  const { container } = props
  if (props) {
    // 注入 actions 实例
    actions.setActions(props)
  }
  instance = new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount(container ? container.querySelector('#app') : '#app')
}

// 独立运行时
if (!window.__POWERED_BY_QIANKUN__) {
  render()
}

export async function bootstrap() {
  console.log('[vue2] product app bootstraped')
}

export async function mount(props) {
  render(props)
}

export async function unmount() {
  instance.$destroy()
  instance.$el.innerHTML = ''
  instance = null
}
