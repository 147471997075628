export default {
  currentShop: (state) => state.currentShop || {},
  partyId: (state) => state.userSession.currentParty.partyId,
  platformMapping: (state) => listToMap(state.nowPlatformList),
  isStore: (state) => state.currentShop?.shopType === 'STORE',
  merchantCode: (state) => state.userSession?.merchantCode,
}

function listToMap(list) {
  let obj = {}
  list.forEach((item) => {
    obj[item.code] = item.text
  })
  return obj
}
