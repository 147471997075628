// js 数字每三位数加一个逗号,

export function toThousands(money) {
  if (typeof money == 'string') {
    return money
  }
  if (money && money != null) {
    money = String(money)
    var left = money.split('.')[0],
      right = money.split('.')[1]
    right = right ? (right.length >= 2 ? '.' + right.substr(0, 2) : '.' + right) : ''
    var temp = left
      .split('')
      .reverse()
      .join('')
      .match(/(\d{1,3})/g)
    return (Number(money) < 0 ? '-' : '') + temp.join(',').split('').reverse().join('') + right
  } else if (money === 0) {
    //注意===在这里的使用，如果传入的money为0,if中会将其判定为boolean类型，故而要另外做===判断
    return 0
  } else {
    return ''
  }
}
